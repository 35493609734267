import { Footer } from '@cfra-nextgen-frontend/shared/src/components/Footer';
import { SearchSyntaxInfo } from '@cfra-nextgen-frontend/shared/src/components/InformationPopup/SearchSyntaxInfo';
import { ItemVariant4 } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant4';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { PageWithComponentInHeader } from '@cfra-nextgen-frontend/shared/src/components/PageLayouts/PageWithComponentInHeader';
import { FiltersModalContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { ChipItem } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/types';
import { UseFiltersFormInputProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { SearchInputVariant2 } from '@cfra-nextgen-frontend/shared/src/components/SearchInput/SearchInputVariant2';
import { getOptionsContainer } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/optionsContainer';
import { TitleDividerSubtitle } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TitleWithSubtitle';
import { ShowHideStrategies, TypeSearch } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TypeSearch';
import { useUsageLogger } from '@cfra-nextgen-frontend/shared/src/hooks/useUsageLogger';
import { getFiltersReqBody, SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ComponentLid, EntityTypeLid } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { getDividerString } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { Box, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { BcLabel } from 'components/BcLabel/BcLabel';
import { determineGetCompanySuggesterOptions } from 'features/topNavigation/companySuggester';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { researchFiltersRequestParams } from 'utils/api';
import { SortOptions } from 'utils/enums';
import {
    horizontalPaddingInPx,
    horizontalPaddingInSu,
    maxPageWidthInPx,
    maxPageWidthInPxIncludingPadding,
} from 'utils/lookAndFeel';
import { pageWithComponentInHeaderStyleProps } from 'utils/styles';
import { defaultSearchUsageReqBody, getPageLid, searchesUsageLoggerProps } from 'utils/usage';
import { FiltersSection } from './FiltersSection';
import { SearchResults } from './SearchResults';
import { SideBarComponentRef } from './utils';

const divider = getDividerString('divider');
const companyFilterName = 'insights.research_report_security__company.research_report_security.company.cfra_company_id';
const searchFilterName = 'search';

type ResearchComponentProps = {
    filtersSectionProps: { getFiltersJsx: UseFiltersFormInputProps['getFiltersJsx'] };
    sideBarComponent?: React.ReactNode;
    queriesKeyFirstElementPostfix?: string;
    screenerSearchByParams?: SearchByParams;
    showTypeSearchComponent?: boolean;
    showOnlyTableView?: boolean;
    showTopLevelSortOptions?: boolean;
    defaultFilters?: Record<string, any>;
    defaultSortOptions?: SortOptions | undefined;
    showBCLabel?: boolean;
    usageConfiguration?: {
        localSearch: {
            onCompanyClickConfig: {
                getRequestBody: (props: {
                    companyId: string;
                    ticker: string;
                    exchangeCode: string;
                }) => Record<string, any>;
            };
            onSearchTermClickConfig: {
                getRequestBody: (props: { searchTerm: string }) => Record<string, any>;
            };
        };
    };
    sideBarComponentRef?: React.RefObject<SideBarComponentRef>;
};

export type ResearchComponentRef = {
    onCompanyClick: (companyId: string, ticker: string, exchangeCode: string) => void;
    onSearchTermClick: (searchTerm: string) => void;
};

// assume to be moved to the shared folder in the future
const ResearchComponentShared = forwardRef<ResearchComponentRef, ResearchComponentProps>(
    (
        {
            filtersSectionProps,
            sideBarComponent,
            queriesKeyFirstElementPostfix,
            screenerSearchByParams,
            showTypeSearchComponent = true, // default to true
            showOnlyTableView,
            showTopLevelSortOptions = true, // default to true
            defaultFilters,
            defaultSortOptions,
            showBCLabel = true, // default to true
            usageConfiguration,
            sideBarComponentRef,
        },
        ref,
    ) => {
        const [externalPostData, setExternalPostData] = useState<Record<string, any>>();
        const [externalChipItems, setExternalChipItems] = useState<Record<string, ChipItem>>();
        const [searchTerms, setSearchTerms] = useState<Array<string>>([]);
        const { setRequestBody } = useUsageLogger(searchesUsageLoggerProps);

        const searchTerm = useMemo(() => searchTerms.join(' OR '), [searchTerms]);

        useEffect(() => {
            sideBarComponentRef?.current?.updateSideBarSearchByParams({
                requestBody: externalPostData,
                search: searchTerm,
            });
        }, [externalPostData, sideBarComponentRef, searchTerm]);

        const onCompanyClick: ResearchComponentRef['onCompanyClick'] = useCallback(
            (companyId: string, ticker: string, exchangeCode: string) => {
                const getOnCompanyClickUsageRequestBody =
                    usageConfiguration?.localSearch?.onCompanyClickConfig?.getRequestBody;
                if (getOnCompanyClickUsageRequestBody) {
                    // save company usage
                    setRequestBody({
                        body: getOnCompanyClickUsageRequestBody({
                            companyId,
                            ticker,
                            exchangeCode,
                        }),
                        enabled: true,
                    });
                }

                setExternalPostData((previousValue: Record<string, any>) => {
                    return {
                        ...previousValue,
                        [companyFilterName]: {
                            values: [...(previousValue?.[companyFilterName]?.values || []), companyId],
                        },
                    };
                });

                const chipId = `${companyFilterName}${divider}${companyId}`;

                const chipItems: Record<string, ChipItem> = {
                    [chipId]: {
                        chip: {
                            label: `${ticker}:${exchangeCode}`,
                            values: '',
                        },
                        stateData: {
                            controlID: chipId,
                            values: [],
                            filterSections: {},
                        },
                    },
                };

                setExternalChipItems((previousValue) => ({
                    ...previousValue,
                    ...chipItems,
                }));
            },
            [setRequestBody, usageConfiguration?.localSearch?.onCompanyClickConfig],
        );

        const onSearchTermClick: ResearchComponentRef['onSearchTermClick'] = useCallback(
            (searchTerm: string) => {
                const getSearchTermUsageRequestBody =
                    usageConfiguration?.localSearch?.onSearchTermClickConfig?.getRequestBody;
                if (getSearchTermUsageRequestBody) {
                    // save search term usage
                    setRequestBody({
                        body: getSearchTermUsageRequestBody({
                            searchTerm,
                        }),
                        enabled: true,
                    });
                }

                const chipId = `${searchFilterName}${divider}${searchTerm}`;

                const chipItems: Record<string, ChipItem> = {
                    [chipId]: {
                        chip: {
                            label: 'Search Term',
                            values: searchTerm,
                        },
                        stateData: {
                            controlID: chipId,
                            values: [],
                            filterSections: {},
                        },
                    },
                };

                setExternalChipItems((previousValue) => ({
                    ...previousValue,
                    ...chipItems,
                }));

                setSearchTerms((previousValue) => [...previousValue, searchTerm]);
            },
            [setRequestBody, usageConfiguration?.localSearch?.onSearchTermClickConfig?.getRequestBody],
        );

        useImperativeHandle(ref, () => ({
            onCompanyClick,
            onSearchTermClick,
        }));

        const clearAllExternalChipItems = useCallback(() => {
            setExternalChipItems(undefined);
            setExternalPostData(undefined);
            setSearchTerms([]);
        }, []);

        const onExternalChipDeleteClick = useCallback((key: string) => {
            const filterAndValue = key.split(divider);

            if (filterAndValue.length !== 2) {
                throw new Error('onExternalChipDeleteClick exception. Invalid chip key');
            }

            const [filter, value] = filterAndValue;

            setExternalChipItems((previousValue) => {
                previousValue && delete previousValue[key];
                return { ...previousValue };
            });

            switch (filter) {
                case companyFilterName:
                    setExternalPostData((previousValue: Record<string, any>) => {
                        const newValue: Record<string, any> = {
                            ...previousValue,
                            [filter]: {
                                values: [
                                    ...(previousValue?.[filter]?.values || []).filter(
                                        (_value: string) => _value !== value,
                                    ),
                                ],
                            },
                        };

                        if (newValue[filter].values.length === 0 && newValue[filter]) {
                            delete newValue[filter];
                        }

                        return newValue;
                    });
                    break;
                case searchFilterName:
                    setSearchTerms((previousValue) => previousValue.filter((_value: string) => _value !== value));
                    break;
                default:
                    break;
            }
        }, []);

        const leftColumnWidthInSu = 8.84;

        const { state } = useLocation();
        const externalSearchTerm = state?.searchTerm;

        useEffect(() => {
            if (typeof externalSearchTerm === 'string' && externalSearchTerm.length > 0) {
                onSearchTermClick(externalSearchTerm);
            }
        }, [externalSearchTerm, onSearchTermClick]);

        const filtersRequestParams: Parameters<typeof FiltersSection>[0]['filtersRequestParams'] = useMemo(() => {
            return [
                {
                    ...researchFiltersRequestParams[0],
                    requestBody: getFiltersReqBody(defaultFilters),
                },
                researchFiltersRequestParams[1],
            ];
        }, [defaultFilters]);

        return (
            <ResultsContextProvider onChipClearAllExternalCallback={clearAllExternalChipItems}>
                <FiltersModalContextProvider>
                    <>
                        <PageWithComponentInHeader
                            {...pageWithComponentInHeaderStyleProps}
                            component={
                                showTypeSearchComponent && (
                                    <Box
                                        sx={{
                                            maxWidth: maxPageWidthInPx,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingTop: '23px',
                                            paddingBottom: '23px',
                                        }}>
                                        <ThemeProvider theme={createTheme()}>
                                            <TypeSearch
                                                showHideStrategy={ShowHideStrategies.UseShowResultsContainer}
                                                showSearchInput
                                                outerOnInputKeyDownCallback={(event) => {
                                                    if (event.key !== 'Enter') {
                                                        return;
                                                    }

                                                    const value = (event.target as HTMLInputElement).value;
                                                    onSearchTermClick(value);
                                                }}
                                                getLeftOptions={determineGetCompanySuggesterOptions({
                                                    size: 25,
                                                    externalOnClickCallback: (data) => {
                                                        onCompanyClick(
                                                            data?._source?.['company_security.company.cfra_company_id'],
                                                            data?._source?.[
                                                                'company_security.security_trading.ticker_symbol'
                                                            ],
                                                            data?._source?.[
                                                                'company_security.security_trading.exchange_lid.exchange_code'
                                                            ],
                                                        );
                                                    },
                                                    passNavigateUrl: false,
                                                    getOptionsContainer,
                                                    queryKeyFirstElementPostfix: queriesKeyFirstElementPostfix,
                                                })}
                                                getRightOptions={({
                                                    inputValue,
                                                    onLinkClickCallback,
                                                    titleWithScrollableAreaProps,
                                                }) => {
                                                    const OptionsContainer =
                                                        getOptionsContainer(titleWithScrollableAreaProps);

                                                    return (
                                                        <OptionsContainer key='Text search search title and search term component'>
                                                            <ItemVariant4
                                                                onClick={() => {
                                                                    onSearchTermClick(inputValue);
                                                                    onLinkClickCallback?.();
                                                                }}
                                                                value={inputValue}
                                                            />
                                                        </OptionsContainer>
                                                    );
                                                }}
                                                SearchInputComponent={SearchInputVariant2}
                                                searchInputComponentProps={{
                                                    afterInputSlot: (
                                                        <Box
                                                            sx={{
                                                                paddingLeft: '8px',
                                                            }}>
                                                            <SearchSyntaxInfo buttonFontSize={21} />
                                                        </Box>
                                                    ),
                                                }}
                                                offset={[-240.5, 19]}
                                                contextBoxStyles={{
                                                    maxHeight: 'calc(95vh - 163px)',
                                                }}
                                                titles={{
                                                    left: 'Companies',
                                                }}
                                                titleNodes={{
                                                    right: (
                                                        <TitleDividerSubtitle
                                                            title='Text Search'
                                                            subTitle='Select text term to add to your query'
                                                        />
                                                    ),
                                                }}
                                            />
                                        </ThemeProvider>
                                    </Box>
                                )
                            }
                            outletComponent={
                                <Box
                                    sx={{
                                        width: '100%',
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <FiltersSection
                                        externalChipItems={externalChipItems}
                                        onExternalChipDeleteClick={onExternalChipDeleteClick}
                                        filtersRequestParams={filtersRequestParams}
                                        {...filtersSectionProps}
                                    />
                                    <Grid
                                        container
                                        spacing={sideBarComponent ? horizontalPaddingInSu : undefined}
                                        sx={{
                                            maxWidth: sideBarComponent
                                                ? `calc(${maxPageWidthInPx} + ${horizontalPaddingInPx * 3}px)`
                                                : maxPageWidthInPxIncludingPadding,
                                            paddingLeft: horizontalPaddingInSu,
                                            paddingRight: horizontalPaddingInSu,
                                        }}>
                                        <Grid item xs={sideBarComponent ? leftColumnWidthInSu : 12}>
                                            {showBCLabel && (
                                                <BcLabel
                                                    containerSx={{
                                                        paddingBottom: '10px',
                                                        marginTop: '-13px',
                                                    }}
                                                />
                                            )}
                                            <SearchResults
                                                searchTerm={searchTerm}
                                                externalPostData={externalPostData}
                                                defaultFilters={defaultFilters}
                                                defaultSortOptions={defaultSortOptions}
                                                queryKeyFirstElementPostfix={queriesKeyFirstElementPostfix}
                                                externalSearchByParams={screenerSearchByParams}
                                                showOnlyTableView={showOnlyTableView}
                                                showTopLevelSortOptions={showTopLevelSortOptions}
                                                gridViewItemContainerSx={sideBarComponent ? {} : { width: '305px' }}
                                            />
                                        </Grid>
                                        {sideBarComponent && (
                                            <Grid item xs={12 - leftColumnWidthInSu}>
                                                {sideBarComponent}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            }
                        />
                        <Footer />
                    </>
                </FiltersModalContextProvider>
            </ResultsContextProvider>
        );
    },
);

export const ResearchComponent = forwardRef<ResearchComponentRef, ResearchComponentProps>(
    ({ usageConfiguration, ...restProps }, ref) => {
        return (
            <ResearchComponentShared
                {...restProps}
                ref={ref}
                usageConfiguration={{
                    localSearch: {
                        onCompanyClickConfig: {
                            getRequestBody: ({ ticker, companyId, exchangeCode }) => ({
                                ...defaultSearchUsageReqBody,
                                page_lid: getPageLid(),
                                component_lid: ComponentLid.LocalSearch,
                                entity_type_lid: EntityTypeLid.Company,
                                primary_entity_id: companyId,
                                detail: {
                                    ticker,
                                    exchange_code: exchangeCode,
                                },
                            }),
                        },
                        onSearchTermClickConfig: {
                            getRequestBody: ({ searchTerm }) => ({
                                ...defaultSearchUsageReqBody,
                                page_lid: getPageLid(),
                                component_lid: ComponentLid.LocalSearch,
                                text: searchTerm,
                            }),
                        },
                    },
                }}
            />
        );
    },
);
