import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { DataItem } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { ApiNames, RequestTypes, UsageRequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { getUsageApiQueryKey, invalidateQueriesByKey } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';

export type PostUsageData = {
    data: DataItem;
};

export type UsageLoggerProps = {
    onSuccess?: (data?: PostUsageData) => void;
    resetStateOnRequestDone?: boolean;
    usageQueryKeys?: Array<string>;
    requestBody?: any;
    config?: any;
    invalidateUsage?: boolean;
    actionType?: UsageRequestTypes;
};

export type UsageLoggerReturnTypes = {
    setEnable: (enabled: boolean) => void;
    setRequestBody: (props: { body: Record<string, any>; enabled?: boolean }) => void;
};

export function useUsageLogger(props: UsageLoggerProps): UsageLoggerReturnTypes {
    const { actionType, onSuccess = () => {}, resetStateOnRequestDone, invalidateUsage = true } = props;
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    const initialState = useMemo(() => {
        return {
            requestBody: props.requestBody || {},
            config: props?.config || { enabled: false },
            noErrorOnNoKeyValuePairs: true,
        };
    }, [props.requestBody, props.config]);

    const [requestParams, setRequestParams] = useState(initialState);

    useEffect(() => {
        if (!sendSingleRequest) {
            throw new Error('useUsageLogger hook exception. sendSingleRequest is not provided.');
        }
    }, [sendSingleRequest]);

    const usageQry = sendSingleRequest?.(
        { ...requestParams },
        {
            requestType: RequestTypes.POST,
            path: 'usage',
            queryKeyFirstElement: 'postUsageQuery',
            apiName: ApiNames.UserManagement,
        },
    ) as UseQueryResult<PostUsageData>;

    const setRequestBody: UsageLoggerReturnTypes['setRequestBody'] = useCallback(
        ({ body, enabled = false }) => {
            setRequestParams((prevState) => ({
                ...prevState,
                requestBody: { ...prevState.requestBody, ...body },
                config: { ...prevState.config, enabled: enabled },
            }));
        },
        [setRequestParams],
    );

    const setEnable = useCallback((enabled: boolean) => {
        setRequestParams((prevState) => ({
            ...prevState,
            config: { ...prevState.config, enabled },
        }));
    }, []);

    const handleOnSuccess = useCallback(
        (usageQry: UseQueryResult<PostUsageData>) => {
            setEnable(false);

            if (invalidateUsage && actionType) {
                invalidateQueriesByKey(getUsageApiQueryKey(actionType));
            }

            if (invalidateUsage && props.usageQueryKeys) {
                props.usageQueryKeys.forEach((key) => {
                    invalidateQueriesByKey(key);
                });
            }

            onSuccess(usageQry?.data);
        },
        [actionType, invalidateUsage, onSuccess, setEnable, props.usageQueryKeys],
    );

    useEffect(() => {
        if (requestParams?.config?.enabled && !usageQry?.isLoading && usageQry.isFetched) {
            handleOnSuccess(usageQry);
        }

        if (requestParams?.config?.enabled && !usageQry?.isLoading && resetStateOnRequestDone) {
            setRequestParams(initialState);
        }
    }, [handleOnSuccess, requestParams?.config?.enabled, usageQry, resetStateOnRequestDone, initialState]);

    return { setEnable, setRequestBody };
}
