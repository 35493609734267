import { ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { LinkGeneratorModes, useLinkGetter } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { ApiNames, RequestTypes, UsageRequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { getUsageApiReqBody } from './usage';

export const getResearchTypeId = (research: any) => {
    let researchTypeId = research['research_report']?.['research_type_id'];
    if (!researchTypeId) {
        researchTypeId = research['research_report.research_type_id'];
    }
    return researchTypeId;
};

export const researchLinkGetterParams: Parameters<typeof useLinkGetter<ScreenerResearchData>> = [
    {
        mode: LinkGeneratorModes.OpenInNewTab,
        getRequestParams: ({ searchByParams, config }) => {
            return [
                {
                    from: 0,
                    size: 1,
                    view: 'pdf',
                    includeData: false,
                    includeMetadata: false,
                    securityType: 'research',
                    // assume request body is provided in the searchByParams parameter
                    // don't sent request until we manully turn it on via config parameter
                    config: { enabled: false },
                    ...searchByParams,
                },
                {
                    requestType: RequestTypes.POST,
                    path: 'research/screener',
                    queryKeyFirstElement: 'researchScreenerQuery',
                    apiName: ApiNames.Research,
                    ...config,
                },
            ];
        },
        getLink: (data) => {
            if (data?.results?.research?.length === 0) {
                return '';
            }
            return data?.results?.research?.[0]?.research_report?.s3_pdf_url;
        },
        enableUsageLog: true,
        usageLoggerProps: {
            invalidateUsage: true,
            actionType: UsageRequestTypes.ReportViewed,
        },
        getPostUsageParams: (data) => {
            if (data?.results?.research?.length === 0) {
                return;
            }

            const research = data?.results?.research?.[0];
            const researchTypeId = getResearchTypeId(research);

            return getUsageApiReqBody(UsageRequestTypes.ReportViewed, {
                research_type_id: researchTypeId,
                id: research.id,
            });
        },
    },
];
