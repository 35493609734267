import { AgGirdCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGridReact } from 'ag-grid-react';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { useMemo, useCallback, useRef } from 'react';

export function ScoreHistory(props: any) {
    let { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
        if (!props.data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }
        const result = extractFromScreenerData({ screenerData: props.data, cardName: 'ScoreHistory' });
        return result;
    }, [props.data]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? defaultMinWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const gridRef = useRef<AgGridReact>(null);
    return (
        <AgGirdCard
            showDefaultExportButton={false}
            ref={gridRef}
            defaultRowHeight={30}
            columnDefs={columnDefs}
            suppressRowClickSelection={true}
            customFlexibleColumns={customFlexibleColumns}
            getResizableMinWidthForColumn={getResizableMinWidthForColumn}
            rowsData={props.data.results.company || []}
            maxNumberOfRowsToDisplay={4}
            gridTheme={['cp-grid-theme', 'ag-row-no-border']}
            headerHeight={42}
        />
    );
}
