import { cloneDeep, merge } from 'lodash';
import { CFRASelectVariant3, CFRASelectVariant3Props, CFRASelectVariant3ThemeOptions } from './CFRASelectVariant3';
import { fontFamilies } from '../../utils/fonts';

const CFRASelectVariant4ThemeOptions = {
    components: {
        MuiSelect: {
            styleOverrides: {
                select: {
                    minHeight: 'unset',
                    lineHeight: '22px',
                    fontSize: '14px !important',
                    fontFamily: fontFamilies.GraphikRegular,
                    letterSpacing: 'normal !important',
                    color: '#3C3C3C !important',
                    paddingTop: '6px !important',
                    paddingBottom: '6px !important',
                    paddingLeft: '12px !important',
                    paddingRight: '12px !important',
                    width: '130px !important',
                    height: '22px !important',
                },
                icon: {
                    right: '14px',
                    top: '13px',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    width: '130px !important',
                    minWidth: 'unset !important',
                    height: '36px !important',
                    padding: '0px !important',
                    letterSpacing: 'normal !important',
                    border: '1px solid #E4E4E4 !important',
                    borderRadius: '10px !important',
                },
            },
        },
    },
};

export function CFRASelectVariant4(props: CFRASelectVariant3Props) {
    return (
        <CFRASelectVariant3
            {...props}
            externalThemeOptions={merge(cloneDeep(CFRASelectVariant3ThemeOptions), CFRASelectVariant4ThemeOptions)}
        />
    );
}