import { useLinkGetter, UseLinkGetterOutputProps } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { useCallback } from 'react';
import { ItemVariant9, ItemVariant9BaseProps } from './ItemVariant9';

type ItemVariant7Props<T> = ItemVariant9BaseProps & {
    handleOuterSetRequestParamsProps: (
        setRequestParamsProps: UseLinkGetterOutputProps<T>['setRequestParamsProps'],
    ) => void;
    useLinkGetterParams: Parameters<typeof useLinkGetter<T>>;
};

export function ItemVariant7<T>({
    handleOuterSetRequestParamsProps,
    useLinkGetterParams,
    ...restProps
}: ItemVariant7Props<T>) {
    const { setRequestParamsProps, extractedLink } = useLinkGetter<T>(...useLinkGetterParams);

    const onClick = useCallback(() => {
        handleOuterSetRequestParamsProps(setRequestParamsProps);
    }, [setRequestParamsProps, handleOuterSetRequestParamsProps]);

    return <ItemVariant9 {...restProps} extractedLink={extractedLink} onClick={onClick} />;
}
