import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { Typography, ETFCard } from '@cfra-nextgen-frontend/shared';
import { getIndustryResearch, getQueryConfig } from '../api/company';
import { useContext, useMemo, useCallback, useRef } from 'react';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { UseQueryResult } from 'react-query';
import { ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { AgGirdCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGridReact } from 'ag-grid-react';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';
import { AgGridThemes, keepNoSidePaddingsOnMove } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/utils';
import { defaultAutosizePadding, defaultTooltipShowDelay } from 'utils/lookAndFeel';

export function IndustryResearch({ enableQuery, gics_code }: { enableQuery: boolean; gics_code: string }) {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    const industryResearch = sendSingleRequest?.(
        getIndustryResearch(enableQuery, gics_code),
        getQueryConfig('getIndustryResearch'),
    ) as UseQueryResult<ScreenerResearchData>;

    const { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
        if (!industryResearch?.data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }

        const result = extractFromScreenerData({
            screenerData: industryResearch?.data,
            cardName: 'industry research',
            outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
            keepNoSidePaddings: true,
        });
        return result;
    }, [industryResearch?.data]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? defaultMinWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const gridRef = useRef<AgGridReact>(null);

    const gridComponent = useMemo(() => {
        const reports = industryResearch.data?.results.research;

        if (reports) {
            for (let i = 0; i < reports.length; i++) {
                const industryLength = reports?.[i]?.research_report_industry?.length || 0;
                for (let j = 0; j < industryLength; j++) {
                    let lookup = reports?.[i]?.research_report_industry?.[j]?.lookups?.lookup_gics;

                    lookup.value__gics_industry =
                        lookup.value__gics_sub_industry ||
                        lookup.value__gics_industry ||
                        lookup.value__gics_industry_group ||
                        lookup.value__gics_sector;
                }
            }
        }

        return (
            <AgGirdCard
                showDefaultExportButton={false}
                ref={gridRef}
                columnDefs={columnDefs}
                suppressRowClickSelection={true}
                customFlexibleColumns={customFlexibleColumns}
                getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                rowsData={reports || []}
                maxNumberOfRowsToDisplay={4}
                gridTheme={AgGridThemes.CpGridTheme}
                useDragScroll
                onColumnMovedGetter={keepNoSidePaddingsOnMove}
                autoSizePadding={defaultAutosizePadding}
                tooltipShowDelay={defaultTooltipShowDelay}
            />
        );
    }, [customFlexibleColumns, columnDefs, industryResearch.data?.results.research, getResizableMinWidthForColumn]);

    return (
            <ETFCard.ETFCard isLoading={false} containerStyles={{ marginTop: '30px' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{ paddingBottom: '10px' }}>
                        <Typography variant='cardSubCaption' sx={{ fontWeight: 500 }}>
                            Industry Research
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {gridComponent}
                    </Grid>
                </Grid>
            </ETFCard.ETFCard>
    );
}
