import { CellRendererParamItemData } from '@cfra-nextgen-frontend/shared/src/components/types/fieldViewData';

export const getRequestParamsPropsVariant1 = (researchId: string) => ({
    searchByParams: {
        requestBody: {
            filters: {
                values: {
                    'insights.research_report._id': { values: [researchId] },
                },
            },
        },
        config: {
            enabled: true,
        },
    },
});

export const getRequestParamsPropsVariant2 = (
    filterNamesToValues?: Record<string, { values: Array<any> }>,
    requestParams?: CellRendererParamItemData['request_params'],
) => ({
    searchByParams: {
        ...(filterNamesToValues && {
            requestBody: {
                filters: {
                    values: filterNamesToValues,
                },
            },
        }),
        // explicitly point this values should be taken for the security purpose
        ...(requestParams?.view && { view: requestParams.view }),
        ...(requestParams?.includeData && { config: requestParams.includeData }),
        config: {
            enabled: true,
        },
    },
});
