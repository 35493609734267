import { styled } from "@mui/material/styles";

export const HideOnMdAndBelow = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        display: 'none'
    }
}));

export const HideOnMdAndAbove = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'none'
    }
}));

export const HideOnSmAndBelow = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    }
}));

export const HideOnSmAndAbove = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        display: 'none'
    }
}));