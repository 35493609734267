import { Box, Skeleton } from '@mui/material';
import { usePdfViewer } from 'hooks/usePdfViewer';
import { useParams, useSearchParams } from 'react-router-dom';

export function PdfViewer() {
    const { id } = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source') ?? undefined;
    const alert_id = searchParams.get('alert_id') ?? undefined;

    if (!id || !source || !alert_id) {
        throw new Error('Missing required parameters: id, source and alert_id are required');
    }

    usePdfViewer(id, source, alert_id);

    return (
        <Box width='100%' height='100%'>
            <Skeleton sx={{ mt: '120px', mx: '20px', height: '30px' }} />
        </Box>
    );
}
