import { ReactComponent as KeyboardArrowDown } from '@cfra-nextgen-frontend/shared/src/assets/icons/arrow-down.svg';
import { CFRARoundedSelect } from '@cfra-nextgen-frontend/shared/src/components/CFRASelect/CFRARoundedSelect';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { ThemeOptions } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';

export type CFRASelectVariant3Props = {
    currentSelection?: string;
    handleSelectionChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
    selectItems: Array<string>;
    placeholder?: string;
    externalThemeOptions?: ThemeOptions;
};

export const CFRASelectVariant3ThemeOptions = {
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    border: '0px !important',
                    padding: '0px !important',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    height: '13.5px',
                    minHeight: 'unset',
                    lineHeight: 1,
                    padding: '0px !important',
                    fontSize: '13px !important',
                    fontFamily: fontFamilies.GraphikRegular,
                    letterSpacing: 'normal !important',
                    color: '#808080 !important',
                },
                icon: {
                    right: '1px',
                    top: '7px',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    width: '100px !important',
                    minWidth: 'unset !important',
                    height: '21px !important',
                    border: '0px !important',
                    padding: '0px !important',
                    letterSpacing: 'normal !important',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    padding: '0px',
                },
                paper: {
                    width: '185px !important',
                    minWidth: 'unset !important',
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    width: '185px',
                    minWidth: 'unset',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '13px !important',
                    fontFamily: fontFamilies.GraphikRegular,
                    color: '#222222',
                    height: '32px !important',
                    borderRadius: '5px',
                    margin: '0px 5px',
                    '&:not(:first-of-type)': {
                        marginTop: '5px',
                    },
                    '&:not(:last-of-type)': {
                        marginBottom: '5px',
                    },
                    '&.Mui-selected': {
                        fontFamily: fontFamilies.GraphikMedium,
                        backgroundColor: '#F3F5FC',
                        color: '#007AB9',
                    },
                },
            },
        },
    },
};

export function CFRASelectVariant3({
    currentSelection,
    handleSelectionChange,
    selectItems,
    placeholder,
    externalThemeOptions,
}: CFRASelectVariant3Props) {
    return (
        <CFRARoundedSelect
            externalTooltipOpen={false}
            paperSxProps={{ transform: 'translate(-3px, -5px) !important' }}
            iconComponent={KeyboardArrowDown}
            themeOptions={externalThemeOptions || CFRASelectVariant3ThemeOptions}
            selectItems={selectItems}
            currentSelection={currentSelection || ''} // prevent "A component is changing a controlled input to be uncontrolled." warning
            handleSelectionChange={handleSelectionChange}
            placeholder={placeholder}
        />
    );
}
