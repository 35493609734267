import { useAuthenticator } from '@aws-amplify/ui-react';
import React, { useEffect, useMemo, useState } from 'react';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { removeDataFromSessionStorage, setDataToSessionStorage } from '../utils/storage';
import { GetAuthenticatedRoutesJsxProps } from './types';
import { getAuthenticatedRoutesJsx, getNotAuthenticatedRoutes, getNotAuthenticatedRoutesJsx } from './utils';

type AppRoutesProps = {
    loginElement: JSX.Element;
    authenticatedRoutesJsxGetterProps: GetAuthenticatedRoutesJsxProps;
};

export const AppRoutes = ({
    authenticatedRoutesJsxGetterProps,
    loginElement
}: AppRoutesProps) => {
    const [resultJsx, setResultJsx] = useState(<></>);

    const { route, user } = useAuthenticator((context) => [context.route, context.user]);

    const routesJsx = useMemo(
        () =>
            getAuthenticatedRoutesJsx({
                ...authenticatedRoutesJsxGetterProps,
                projectSpecificResources: {
                    ...authenticatedRoutesJsxGetterProps.projectSpecificResources,
                    userId: user?.username,
                },
            }),
        [user?.username, authenticatedRoutesJsxGetterProps],
    );
    const authenticatedRouter = useMemo(() => {
        return createBrowserRouter(createRoutesFromElements(routesJsx));
    }, [routesJsx]);

    const notAuthenticatedRoutesJsx = useMemo(
        () => getNotAuthenticatedRoutesJsx(getNotAuthenticatedRoutes(loginElement)),
        [loginElement],
    );
    const notAuthenticatedRouter = useMemo(() => {
        return createBrowserRouter(createRoutesFromElements(notAuthenticatedRoutesJsx));
    }, [notAuthenticatedRoutesJsx]);

    useEffect(() => {
        if (route === 'setup' || route === 'signIn') {
            const currentRoute = window.location.href.replace(window.location.origin, '');
            if (!currentRoute.includes('login')) {
                setDataToSessionStorage('preLoginRoute', currentRoute);
            }
            setResultJsx(<RouterProvider router={notAuthenticatedRouter} />);
            return;
        }

        if (route === 'authenticated' && user?.username) {
            removeDataFromSessionStorage('preLoginRoute');
            setResultJsx(<RouterProvider router={authenticatedRouter} />);
            return;
        }
    }, [authenticatedRouter, notAuthenticatedRouter, route, user?.username]);

    return resultJsx;
};
