import { ETFLinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFLinkButton';
import ETFModal from '@cfra-nextgen-frontend/shared/src/components/ETFModal';
import { ItemVariant8 } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant8';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import {
    TypographyStyle21,
    TypographyStyle7,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import Box from '@mui/material/Box';
import React from 'react';

type PopupVariant1Props = {
    linkText: string;
    description: string;
    textToFilePath: Record<string, string>;
    title: string;
    titleRight: string;
};

const leftColumnWidthInSu = 7.15358;

export const PopupVariant1ModalSx = {
    titleStyle: {
        fontFamily: fontFamilies.GraphikMedium,
        fontSize: '16px',
        lineHeight: '28px',
        letterSpacing: '0.1%',
        color: '#002B5A',
        paddingBottom: '11px',
    },
    closeButtonFontSize: 22,
    modalBoxStyles: {
        display: 'block',
        maxWidth: '1376px',
        width: '70%',
        maxHeight: '90%',
        overflowY: 'hidden',
        padding: '21px 24px 28px 24px',
    },
};

export function PopupVariant1({ linkText, description, textToFilePath, title, titleRight }: PopupVariant1Props) {
    const getOpenModalButton = (handleOpen: () => void) => {
        return (
            <Box
                sx={{
                    display: 'inline',
                }}>
                <ETFLinkButton
                    onClick={handleOpen}
                    text={linkText}
                    sx={{
                        minWidth: '0px',
                        height: '12px',
                        fontSize: '12px',
                        fontFamily: fontFamilies.GraphikSemibold,
                        textDecoration: 'underline',
                        color: '#007AB9',
                    }}
                />
            </Box>
        );
    };

    return (
        <>
            <ETFModal getOpenComponent={getOpenModalButton} title={title} {...PopupVariant1ModalSx}>
                <Grid
                    container
                    sx={{
                        maxHeight: 'calc(90vh - 81px)',
                        overflowY: 'auto',
                        ...scrollbarThemeV3,
                    }}>
                    <Grid
                        item
                        xs={leftColumnWidthInSu}
                        sx={{
                            paddingRight: '28px',
                        }}>
                        <TypographyStyle7
                            sx={{
                                lineHeight: '22px',
                                paddingTop: '2px',
                            }}>
                            {description}
                        </TypographyStyle7>
                    </Grid>
                    <Grid item xs={12 - leftColumnWidthInSu}>
                        <TypographyStyle21
                            sx={{
                                lineHeight: '24px',
                                paddingBottom: '11px',
                            }}>
                            {titleRight}
                        </TypographyStyle21>
                        {React.Children.toArray(
                            Object.keys(textToFilePath).map((text, index) => {
                                return (
                                    <ItemVariant8
                                        key={index}
                                        text={text}
                                        onClick={() => window.open(textToFilePath[text])}
                                    />
                                );
                            }),
                        )}
                    </Grid>
                </Grid>
            </ETFModal>
        </>
    );
}
