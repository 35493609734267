import DividerStyle1 from '@cfra-nextgen-frontend/shared/src/components/Divider/StyledDividers';
import { ResearchDescriptionText } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/styledComponents';
import { useLinkGetter, UseLinkGetterOutputProps } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { Box, styled, Typography } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import React, { Fragment, useMemo, Dispatch, useEffect } from 'react';
import { IconWithLoading } from '@cfra-nextgen-frontend/shared/src/components/Icon/IconWithLoading';
import { iconConfigurationVariant1 } from '@cfra-nextgen-frontend/shared/src/components/Icon/iconConfigurations';

const DetailsText = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '13px',
    color: '#555555',
    lineHeight: 1,
}));

const TitleText = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikSemibold,
    fontSize: '15px',
    color: '#002B5A',
    lineHeight: 1,
}));

export function ItemVariant2<T>({
    firstRowItems,
    icon,
    innerRef,
    secondRowText,
    thirdRowText,
    useLinkGetterParams,
    setLink,
    handleOuterSetRequestParamsProps,
    externalOnClick,
}: {
    firstRowItems: Array<string | undefined>;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    innerRef?: React.Ref<HTMLButtonElement>;
    secondRowText: string;
    thirdRowText: string | null;
    useLinkGetterParams: Parameters<typeof useLinkGetter<T>>;
    setLink?: Dispatch<string>;
    handleOuterSetRequestParamsProps?: (
        setRequestParamsProps: UseLinkGetterOutputProps<T>['setRequestParamsProps'],
    ) => void;
    externalOnClick?: () => void;
}) {
    const { setRequestParamsProps, extractedLink, isLoading, isError } = useLinkGetter<T>(...useLinkGetterParams);

    const iconConfiguration = useMemo(() => {
        if (isError) {
            return iconConfigurationVariant1.error;
        }

        return iconConfigurationVariant1.normal;
    }, [isError]);

    useEffect(() => {
        if (extractedLink && setLink) {
            setLink(extractedLink);
        }
    }, [extractedLink, setLink]);

    const onClick = () => {
        externalOnClick?.();
        handleOuterSetRequestParamsProps?.(setRequestParamsProps);
    };

    return (
        <ButtonBase
            ref={innerRef}
            onClick={onClick}
            sx={{
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                width: '100%',
                height: '112px',
                minHeight: '112px',
                borderRadius: '5px',
                padding: '13px 18px',
                display: 'flex',
                flexDirection: 'column',
                '&:hover': {
                    backgroundColor: '#F3F5FC',
                },
            }}>
            {/* First Row */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    height: '26.75px',
                    minHeight: '26.75px',
                    paddingBottom: '3px',
                }}>
                <Box
                    sx={{
                        width: '18px',
                        height: '23.75px',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    <IconWithLoading icon={icon} isLoading={isLoading} iconConfiguration={iconConfiguration} />
                </Box>
                {firstRowItems.map(
                    (item, index) =>
                        item && (
                            <Fragment key={index}>
                                <DividerStyle1 />
                                <Box
                                    sx={{
                                        height: '18px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    <DetailsText key={item}>{item}</DetailsText>
                                </Box>
                            </Fragment>
                        ),
                )}
            </Box>
            {/* Second Row */}
            <Box
                sx={{
                    height: '19.25px',
                    minHeight: '19.25px',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-start',
                }}>
                <TitleText
                    sx={{
                        textAlign: 'left',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        wordWrap: 'break-word',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textDecoration: 'none',
                    }}>
                    {secondRowText}
                </TitleText>
            </Box>
            {/* Third Row */}
            <Box>
                <ResearchDescriptionText
                    sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        wordWrap: 'break-word',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textDecoration: 'none',
                        textAlign: 'left',
                    }}>
                    {thirdRowText}
                </ResearchDescriptionText>
            </Box>
        </ButtonBase>
    );
}
