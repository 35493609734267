import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { Grid } from '@mui/material';
import { ResearchComponent } from 'components/ResearchComponent';
import { SIZE_WHEN_SIDEBAR } from 'components/ResearchComponent/components/constants';
import { getFiltersJsxVariant1 } from 'components/ResearchComponent/components/getFiltersJsx';
import { MoreInsights } from 'components/ResearchComponent/components/MoreInsights';
import { PopularSearches } from 'components/ResearchComponent/components/PopularSearches';
import { ResearchComponentRef } from 'components/ResearchComponent/components/ResearchComponent';
import { useRef } from 'react';
import { sectorImageMap } from 'utils/mappings';

const getFiltersJsx = getFiltersJsxVariant1({
    pillsRowComponentProps: {
        filterMetadataKey: 'insights.research_report.composite_gics_sector_code',
        imageMap: sectorImageMap,
    },
    virtualizeAutocompleteComponentsProps: [
        {
            defaultInputLabel: 'Sub-Industry',
            filterMetadataKey: 'insights.research_report.composite_gics_sub_industry_code',
        },
        {
            defaultInputLabel: 'Document Type',
            filterMetadataKey: 'insights.research_report.research_type_name',
        },
        {
            defaultInputLabel: 'Region',
            filterMetadataKey: 'insights.research_report.composite_region_country_lid',
        },
        {
            defaultInputLabel: 'Author',
            filterMetadataKey: 'insights.research_report_author.analyst_id',
        },
    ],
    dateRangePickerComponentProps: {
        filterMetadataKey: 'insights.research_report.publish_timestamp',
    },
});

type sideBarComponentRef = {
    updateSideBarSearchByParams: (searchByParams: SearchByParams) => void;
}

export function ResearchHubHome() {
    const researchComponentRef = useRef<ResearchComponentRef>(null);
    const sideBarComponentRef = useRef<sideBarComponentRef>(null);

    return (
        <ResearchComponent
            ref={researchComponentRef}
            sideBarComponentRef={sideBarComponentRef}
            filtersSectionProps={{
                getFiltersJsx,
            }}
            sideBarComponent={
                <Grid container gap={4.5}>
                    <Grid item xs={12}>
                        <PopularSearches
                            onSearchTermPillClick={(searchTerm) => {
                                if (!researchComponentRef.current) {
                                    return;
                                }

                                researchComponentRef.current.onSearchTermClick(searchTerm);
                            }}
                            onCompanyPillClick={(primaryEntityId, ticker, exchangeCode) => {
                                if (!researchComponentRef.current) {
                                    return;
                                }

                                researchComponentRef.current.onCompanyClick(primaryEntityId, ticker, exchangeCode);
                            }}
                        />
                    </Grid>
                    <Grid item container xs={12}>
                        <MoreInsights ref={sideBarComponentRef} />
                    </Grid>
                </Grid>
            }
            queriesKeyFirstElementPostfix='research_hub'
            screenerSearchByParams={{
                view: 'research_hub',
                size: SIZE_WHEN_SIDEBAR,
            }}
        />
    );
}
