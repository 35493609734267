import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { RoundedIconTextButton, Typography, ETFCard } from '@cfra-nextgen-frontend/shared';
import { ComponentProps } from '../types/company';
import { ReadMoreCore } from '@cfra-nextgen-frontend/shared/src/components/ReadMore/ReadMoreCore';
import { ReactComponent as PdfIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/PdfIcon.svg';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { ETFLinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton';
import { PageTheme, BespokeTheme } from 'components/themes/theme';
import { maxPageWidthInPx } from 'utils/lookAndFeel';

export function CompanyProfile({ company, handleLinkClick, reportID }: ComponentProps) {
    if (company.isLoading) return <ETFCard.ETFCard isLoading={company.isLoading} />;

    if (company.data?.results?.company === undefined || company.data?.results?.company.length === 0)
        return <ETFCard.ETFEmptyCard cardLabel='Company Profile' />;

    const sector = company.data.results.company[0].company_security.company_sector as any;
    const description =
        company.data.results.company[0].company_security.company_description?.business_description || '';

    return (
        <ETFCard.ETFCard isLoading={company.isLoading} containerStyles={{ }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sx={{ borderBottom: '1px solid #E4E4E4', paddingBottom: '15px' }}>
                    <Typography variant='cardSubCaption'>Company Profile</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='cardRegular'>Industry/Sector:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='cardRegular' sx={{ lineHeight: { xs: '20px', sm: '36px' } }}>{sector?._lookup_gics_ind_lid?.value}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='cardRegular' sx={{ display: { xs: 'none', sm: 'inline' }}}>Industry Risk Assessment Profiles (IRAPs):</Typography>
                    <Typography variant='cardRegular' sx={{ display: { xs: 'inline', sm: 'none' }}}>IRAPs:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <ETFLinkButton
                        onClick={() => {
                            if (handleLinkClick) {
                                if (reportID === '') {
                                    window.open('/accounting-lens/reports/industry');
                                } else {
                                    handleLinkClick(reportID || '');
                                }
                            }
                        }}
                        text={sector?.lookup_gics_subind_lid?.value}
                        startIcon={
                            <CustomSvgIcon
                                component={PdfIcon}
                                viewBox='0 0 18 18'
                                sx={{
                                    width: '18px',
                                    fill: '#002B5A',
                                    '&:hover': {
                                        fill: '#007AB9',
                                    },
                                }}
                            />
                        }
                        sx={{
                            ...PageTheme.typography.cardLink,
                            paddingLeft: '3px',
                            textAlign: 'left',
                            lineHeight: { xs: '20px', sm: '36px' }
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='cardRegular'>Request Report:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <RoundedIconTextButton
                        type='submit'
                        theme={BespokeTheme}
                        buttonText={'Request Bespoke'}
                        onClickCallback={() => {}}
                    />
                </Grid>
                <Grid item xs={12} sx={{ borderTop: '1px solid #E4E4E4', paddingTop: '15px', marginTop: '15px' }}>
                    <Typography variant='cardDescriptionTitle'>Description</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='cardDescriptionText'>
                        <ReadMoreCore
                            textLengthToShowReadMore={200}
                            modalTitle='Company Description'
                            linkText='more'
                            showDots={true}
                            linkSx={{
                                ...PageTheme.typography.cardLink,
                                justifyContent: 'left',
                                lineHeight: '22px',
                                letterSpacing: '0',
                            }}
                            text={description}
                            maxWidth={maxPageWidthInPx}
                        />
                    </Typography>
                </Grid>
            </Grid>
        </ETFCard.ETFCard>
    );
}
