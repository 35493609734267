import { SharedTopNavigation } from '@cfra-nextgen-frontend/shared/src/components/TopNavigation/SharedTopNavigation';
import { getOptionsContainer } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/optionsContainer';
import { ShowHideStrategies } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TypeSearch';
import { UserEntitlementsResponseData } from '@cfra-nextgen-frontend/shared/src/types/userEntitlements';
import { UsageLoggerReturnTypes, useUsageLogger } from '@cfra-nextgen-frontend/shared/src/hooks/useUsageLogger';
import { determineGetCompanySuggesterOptions } from 'features/topNavigation/companySuggester';
import { ComponentLid, EntityTypeLid } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { determineGetResearchSuggesterOptions } from 'features/topNavigation/researchSuggester';
import { topNavItems } from 'features/topNavigation/topNavItems';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { PageNames, PageNamesToRoutePaths } from 'utils/enums';
import { maxPageWidthInPx } from 'utils/lookAndFeel';
import { defaultSearchUsageReqBody, getPageLid, searchesUsageLoggerProps } from 'utils/usage';
import { useMakeIndependent } from '@cfra-nextgen-frontend/shared/src/hooks/useMakeIndependent';

const companySuggesterSize = 25;
const researchSuggesterSize = 10;

export function TopNavigation({ userEntitlements }: { userEntitlements?: UserEntitlementsResponseData }) {
    const { setRequestBody } = useUsageLogger(searchesUsageLoggerProps);

    const { independentValue: setRequestBodyRef } = useMakeIndependent({
        valueGetter: () => (props: Parameters<UsageLoggerReturnTypes['setRequestBody']>[0]) => setRequestBody(props),
        defaultValue: () => {},
    });

    const memoisedTopNavItems = useMemo(() => {
        const topNavItemCopy = cloneDeep(topNavItems);
        Object.keys(topNavItemCopy).forEach((key) => {
            const topNavItem = topNavItemCopy[key];
            if (topNavItem.shouldHideItem?.(userEntitlements)) {
                delete topNavItemCopy[key]; // Enitre Object (top level) to delete
            } else {
                // Check sub links
                const newlinks = topNavItem.popup?.links.filter((link) => {
                    return !link.shouldHidelink?.(userEntitlements);
                });
                if (topNavItemCopy[key].popup && newlinks && newlinks.length > 0) {
                    topNavItemCopy[key].popup = {
                        title: topNavItemCopy[key].popup?.title || '',
                        description: topNavItemCopy[key].popup?.description || '',
                        links_title: topNavItemCopy[key].popup?.links_title || '',
                        links: newlinks,
                    };
                }
            }
        });
        return topNavItemCopy;
    }, [userEntitlements]);

    const result = useMemo(() => {
        return (
            <SharedTopNavigation
                maxWidth={maxPageWidthInPx}
                topNavItems={memoisedTopNavItems}
                typeSearchProps={{
                    showHideStrategy: ShowHideStrategies.UseShowSearchInput,
                    topLinkParams: {
                        text: `Go to ${PageNames.ResearchHub}`,
                        pathname: PageNamesToRoutePaths[PageNames.ResearchHub],
                    },
                    getLeftOptions: determineGetCompanySuggesterOptions({
                        size: companySuggesterSize,
                        getOptionsContainer,
                        externalOnClickCallback: (data) => {
                            const source = data?.['_source'];
                            setRequestBodyRef.current({
                                body: {
                                    ...defaultSearchUsageReqBody,
                                    page_lid: getPageLid(),
                                    component_lid: ComponentLid.GlobalSearch,
                                    entity_type_lid: EntityTypeLid.Company,
                                    primary_entity_id: source?.['company_security.company.cfra_company_id'],
                                    detail: {
                                        ticker: source?.['company_security.security_trading.ticker_symbol'],
                                        exchange_code:
                                            source?.['company_security.security_trading.exchange_lid.exchange_code'],
                                    },
                                },
                                enabled: true,
                            });
                        },
                    }),
                    getRightOptions: determineGetResearchSuggesterOptions({
                        size: researchSuggesterSize,
                        getOptionsContainer,
                        onOptionClick: (searchTerm) => {
                            setRequestBodyRef.current({
                                body: {
                                    ...defaultSearchUsageReqBody,
                                    page_lid: getPageLid(),
                                    component_lid: ComponentLid.GlobalSearch,
                                    text: searchTerm,
                                },
                                enabled: true,
                            });
                        },
                    }),
                    titles: {
                        left: 'Companies',
                        right: 'Research',
                    },
                }}
            />
        );
    }, [memoisedTopNavItems, setRequestBodyRef]);

    return result;
}
