import { InfiniteOptionsRef } from '@cfra-nextgen-frontend/shared/src/components/InfiniteScroll/InfiniteOptions';
import { SearchInputVariant3 } from '@cfra-nextgen-frontend/shared/src/components/SearchInput/SearchInputVariant3';
import { SearchInputProps } from '@cfra-nextgen-frontend/shared/src/components/SearchInput/types';
import { useClickableAreas } from '@cfra-nextgen-frontend/shared/src/hooks/useClickableAreas';
import { Box, Popper, SxProps } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { scrollbarThemeV3 } from '../themes/theme';
import { GetDefaultOptionsComponentProps, GetOptionsBaseProps } from './TypeSearch';

export type CompanyTypeSearchProps = {
    showSearchInput: boolean;
    onClickOutsideCallback?: () => void;
    topLinkParams?: {
        text: string;
        pathname: string;
    };
    getResultOptions: (props: GetOptionsBaseProps) => React.ReactNode;
    getDefaultResultOptions?: (props: GetDefaultOptionsComponentProps) => React.ReactNode;
    SearchInputComponent?: React.FC<SearchInputProps>;
    searchInputComponentProps?: SearchInputProps;
    offset?: [number, number];
    contextBoxStyles?: SxProps;
    titles?: {
        left?: string;
        right?: string;
    };
    titleNodes?: {
        left?: React.ReactNode;
        right?: React.ReactNode;
    };

    scrollableAreaContainerWrapperSx?: SxProps;
    scrollableAreaContainerSx?: SxProps;
    searchQuerySize?: number;
    clearInputOnSelection?: boolean;
};

export function CompanyTypeSearch({
    showSearchInput,
    onClickOutsideCallback,
    getResultOptions,
    getDefaultResultOptions,
    SearchInputComponent = SearchInputVariant3,
    searchInputComponentProps = { autoFocusInput: true },
    offset = [-24, 26],
    contextBoxStyles,
    scrollableAreaContainerSx,
    clearInputOnSelection,
}: CompanyTypeSearchProps) {
    const [inputValue, setInputValue] = useState<string>('');
    const [inputNode, setInputNode] = useState<HTMLInputElement | null>(null);
    const [showResultsContainer, setShowResultsContainer] = useState<boolean>(true);

    const { clickableAreasRefs } = useClickableAreas<HTMLInputElement | HTMLDivElement>({
        clickOutsideCallback: () => {
            onClickOutsideCallback?.();
            setShowResultsContainer(false);
        },
        numberOfAreas: 2,
    });

    const setFirstAreaRef = useCallback(
        (el: HTMLDivElement | null) => {
            clickableAreasRefs.current[0] = el;
        },
        [clickableAreasRefs],
    );

    const setSecondAreaRef = useCallback(
        (el: HTMLDivElement | null) => {
            clickableAreasRefs.current[1] = el;
        },
        [clickableAreasRefs],
    );

    useEffect(() => {
        if (!showSearchInput || !inputNode) {
            return;
        }

        // automatically fill search input on reopen when ShowHideStrategies.UseShowSearchInput strategy
        inputNode.value = inputValue;

        // automatically focus search input on open
        if (searchInputComponentProps?.autoFocusInput) inputNode.focus();
    }, [showSearchInput, inputValue, inputNode, searchInputComponentProps?.autoFocusInput]);

    const resetLeftOptionsRef = useRef<InfiniteOptionsRef | null>(null);
    const resetRightOptionsRef = useRef<InfiniteOptionsRef | null>(null);

    const debouncedSetInputValueHandler = useMemo(
        () =>
            debounce((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setInputValue(event.target.value);
                resetLeftOptionsRef?.current?.resetOptions?.();
                resetRightOptionsRef?.current?.resetOptions?.();
            }, 200),
        [],
    );

    const resultOptions = useMemo(() => {
        return getResultOptions?.({
            inputValue,
            resetOptionsRef: resetLeftOptionsRef,
            onLinkClickCallback: () => {
                onClickOutsideCallback?.();
                setShowResultsContainer(false);
                if (clearInputOnSelection) {
                    setInputValue('');
                }
            },
        });
    }, [inputValue, getResultOptions, resetLeftOptionsRef, onClickOutsideCallback, clearInputOnSelection]);

    const defaultResultOptions = useMemo(() => {
        return getDefaultResultOptions?.({
            onLinkClickCallback: () => {
                onClickOutsideCallback?.();
                setShowResultsContainer(false);
            },
        });
    }, [getDefaultResultOptions, onClickOutsideCallback]);

    if (!showSearchInput) {
        // hide input and search results when ShowHideStrategies.UseShowSearchInput strategy and showSearchInput is false
        return null;
    }

    return (
        <>
            <SearchInputComponent
                clearButtonCallback={() => setInputValue('')}
                onChange={(event) => {
                    setShowResultsContainer(true);
                    debouncedSetInputValueHandler(event);
                }}
                onClick={() => setShowResultsContainer(true)}
                showInput
                textFieldRefCallback={(node) => setInputNode(node)}
                wrapperRefCallback={setFirstAreaRef}
                {...searchInputComponentProps}
            />
            {/* Search results block */}
            {((inputNode && showResultsContainer && inputValue) || getDefaultResultOptions) && (
                <Popper
                    ref={setSecondAreaRef}
                    open
                    anchorEl={inputNode}
                    style={{
                        zIndex: 3000,
                    }}
                    placement='bottom-start'
                    modifiers={[{ name: 'offset', options: { offset } }]}>
                    {({ TransitionProps }) => (
                        <Box
                            {...TransitionProps}
                            sx={{
                                display: 'block',
                                border: '1px solid #ddd',
                                backgroundColor: '#fff',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                                borderRadius: '4px',
                                padding: '11px 8px',
                                width: '500px',
                                height: '200px',
                                maxHeight: '200px',
                                ...contextBoxStyles,
                            }}>
                            <Box sx={{ display: 'flex', height: '175px' }}>
                                {/* Scrollable Area */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        height: '100%',
                                        overflowY: 'auto',
                                        alignItems: 'flex-start',
                                        padding: '0px 12px',
                                        ...scrollbarThemeV3,
                                        ...scrollableAreaContainerSx,
                                    }}>
                                    {inputNode && showResultsContainer && inputValue
                                        ? resultOptions
                                        : defaultResultOptions}
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Popper>
            )}
        </>
    );
}
