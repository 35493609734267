import { ReactComponent as Glass } from '@cfra-nextgen-frontend/shared/src/assets/icons/glass.svg';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { createTheme } from '@mui/material';
import { pillsTooltipTheme, PillVariant1, PillVariant1Props } from './PillVariant1';

export const pillVariant2TooltipTheme = createTheme(pillsTooltipTheme, {
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: '#222222',
                    fontSize: '13.5px',
                },
            },
        },
    },
});

const containerSx = {
    margin: '0px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #CCCCCCB2',
};

const buttonSx = {
    padding: '9px 13px',
};

export function PillVariant2({ content, ...props }: PillVariant1Props) {
    return (
        <PillVariant1
            {...props}
            externalTooltipTheme={pillVariant2TooltipTheme}
            containerSx={containerSx}
            buttonSx={buttonSx}
            content={
                <>
                    <CustomSvgIcon
                        component={Glass}
                        viewBox='0 0 14 14'
                        sx={{
                            width: '14px',
                            height: '14px',
                            fill: '#002B5A',
                            marginRight: '8px',
                        }}
                    />
                    {content}
                </>
            }
        />
    );
}
