import { getRequestParamsPropsVariant1 } from "@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter";
import { LinkGeneratorModes, useLinkGetter } from "@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter";
import { UsageRequestTypes, UsageSourceLid } from "@cfra-nextgen-frontend/shared/src/utils/enums";
import { openExternalLink } from "@cfra-nextgen-frontend/shared/src/utils/links";
import { useEffect } from "react";
import { getResearchTypeId, researchLinkGetterParams } from "utils/researchLinkGetter";
import { getUsageApiReqBody } from "utils/usage";

export const usePdfViewer = (id: string, source: string, alert_id: string) => {
    const { setRequestParamsProps, extractedLink } = useLinkGetter({
        ...researchLinkGetterParams[0],
        mode: LinkGeneratorModes.ExtractLink,
        getPostUsageParams: (data) => {
            if (!data?.results?.research?.length) return;

            const research = data.results.research[0];
            const researchTypeId = getResearchTypeId(research);

            return getUsageApiReqBody(UsageRequestTypes.PdfViewerViewed, {
                research_type_id: researchTypeId,
                id: research.id,
                alert_id: Number(alert_id),
                source_lid: source === 'email' ? UsageSourceLid.Email : undefined,
            });
        },
    });

    useEffect(() => {
        if (id) {
            setRequestParamsProps(getRequestParamsPropsVariant1(id));
        }
    }, [id, setRequestParamsProps]);

    useEffect(() => {
        if (extractedLink) {
            openExternalLink(extractedLink, '_self');
        }
    }, [extractedLink]);

    return { extractedLink };
};
